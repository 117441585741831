// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = is_lazy_load ? false : true;

$(function () {
    setTimeout(function(){

        if($('#FreeShippingBanner').length) {

            $("body").removeClass("promo_on");
            $("body").addClass("global_e_banner");
        }
    }, 800);

    var topAccount = $(".block_top_item.is_logged");
    var topProduct = $("#cart_top, #wishlist_top");
    var topConnexion = $('.connexion_top');
    var menuShade = $('#shad_menu');
    var topaCartDeatil = $('#show_top_cart');
    var topRecherche = $("#trigger_recherche");
    var topSearch = $('.wrap_search_field');
    var searchInput = $('#search_home');

    $(topRecherche).on('click touchstart', function (evt) {
        if (!topSearch.hasClass("appear")) {
            evt.preventDefault();
            topSearch.addClass("appear");
            topSearch.show();
            searchInput.focus();
            menuShade.addClass("actif_search");
        }
        else{
            topSearch.removeClass("appear");
            topSearch.hide();
            menuShade.removeClass("actif_search");
        }
    });

    $('body').on('click','#searchComponent .title_search h1',function() {
        $('#trigger_recherche').click();
        $('#search_home').focus();
    });

    menuShade.on("touchstart", function(){
        topAccount.removeClass("hover");
        topConnexion.removeClass("appear");
        menuShade.removeClass("actif rollPanier");
    });

    $(".block_top_item.is_logged > a").on("touchstart", function(e){
        e.preventDefault();
        topAccount.addClass("hover");
        topConnexion.addClass("appear");
        menuShade.addClass("actif rollPanier");
    });

    $(topAccount).on('mouseenter ', function (evt) {
        $(this).addClass("underlined");

        if (!topConnexion.hasClass("appear")) {
            evt.preventDefault();
            topConnexion.addClass("appear");
            /*topConnexion.stop().slideDown(400);*/
            topaCartDeatil.removeClass("appear");
            menuShade.addClass("actif rollPanier");
        }
    });

    $(menuShade).on('click touchstart', function () {
        if (!topSearch.hasClass("actif_search")) {
            topSearch.removeClass("appear");
            topSearch.hide();
            menuShade.removeClass("actif_search");
        }
    });

    topAccount.on('mouseleave', function () {
        $(this).removeClass("underlined");
        closeMonCompte();
    });

    function closeMonCompte(){
        var topConnexion = $('.connexion_top');
        if (topConnexion.hasClass("appear")) {
            topConnexion.removeClass("appear");
            $(this).removeClass("underlined");
            $('#shad_menu').removeClass("actif rollPanier");
        }
    }

    topProduct.on('mouseenter touch', function (evt) {
        $(this).addClass("underlined");

        if(!$(this).children('#show_top_cart').hasClass("hide")){
            evt.preventDefault();
            topProductAppear($(this));
            $(this).unbind('touch');
        }
    });

    topProduct.on('mouseleave', function () {
        $(this).removeClass("underlined");
        topProductDesappear();
    });

    var menu_timeout = setTimeout(null);

    $(".menu_itm_hover").on("touchstart", function (evt) {
        var mainmenu = $("nav.header_main_wrapper");
        var submenu = $(".submenu_wrapper", this);
        $(".submenu_wrapper", !this).hide();
        clearTimeout(menu_timeout);

        if (!mainmenu.hasClass("actif")) {
            $(this).addClass("hovering");
            evt.preventDefault();
            menuShade.addClass("actif");
            mainmenu.addClass("actif");
            menu_timeout = setTimeout(function () {
                submenu.stop(true, true).fadeIn(100, 'easeOutExpo').css({"display":"flex" , "opacity":"1"});
            }, 250);
        } else {
            if (!$(this).hasClass("hovering")) {
                evt.preventDefault();
                $(this).addClass("hovering");
            }

            $(".submenu_wrapper").css("zIndex", 10);
                submenu.show().css({"display":"flex" , "opacity":"1"});
                submenu.css("zIndex", 13);

            if (submenu.is(':visible')) {
                menuShade.addClass("actif");
            }
        }
    });

    $(".return_menu").on("click", function () {
        if($(".menu_itm_hover.hovering").length){
            var activeID = $(".menu_itm_hover.hovering").attr("id");

            $('#' + activeID).removeClass('hovering');
            $('#' + activeID).find('.ss_menu_categ_wrapper').css({"opacity":"0", "z-index":"-1"});
        }
    });

    $("#shad_menu").on("touchstart", function (evt) {
        if ($("#shad_menu.actif").length){
            menuShade.removeClass("actif");
            $("nav.header_main_wrapper").removeClass("actif");
            $(".menu_itm_hover").removeClass("hovering");
            $(".submenu_wrapper").hide();
        }
    });

    $('.menu_categ_wrapper').on("mouseenter", function(){
        //Si on est en résolution supérieure à 1201, on gère l'effet de hover sur les items du menu
        if (window.matchMedia("(max-width: 1023px)").matches) {
            $(".menu_itm_hover .title_categ").on("click", function (evt) {
                evt.preventDefault();
                var submenuBurger = $(".submenu_wrapper");
                $(this).addClass("actifBurger");
            });
            $(".return_menu").on("click", function () {
                $(".menu_itm_hover .title_categ").removeClass("actifBurger");
            });
        } else{
            $(".menu_itm_hover").on("mouseenter", function(){triggerShad("menu", "add")});
            $(".menu_itm_hover").on("mouseleave", function(){triggerShad("menu", "remove")});
        }
    })

    $('.productVisualMulti').on('mouseenter', function() {
        if ($(this).find('.swiper-slide').length > 1 && !$(this).hasClass('swiper-container-initialized')) {
            new Swiper($(this), {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.rollover_rayonsNext',
                    prevEl: '.rollover_rayonsPrev',
                },
                preloadImages: false,
                lazy: isLazyLoad,
                loadPrevNext: true,
                loadOnTransitionStart: true,
                watchSlidesVisibility: true
            });
        }
    });

    if ($(".promo_swiper .swiper-slide").length > 0) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            slidesPerView: 'auto',
            autoplay: true,
            speed: 1500,
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            }
        });
    }

    if($('.switchLangWrapper a').length){
        $('.switchLang').on('mouseenter touch', function () {
            $('.switchLangWrapper').fadeIn().css('display', 'flex');
        });

        $('.switchLang').on('mouseleave', function () {
            $('.switchLangWrapper').fadeOut();
        });
    }

    if($('#filter_sticky').length && $('.block_filters .filter_wrapper').length > 5){
        $('.block_filters').addClass('calibrate');
    }


    // Display Filter on Rayon Page
    if($('.block_filters').length > 0){
        /* Footer accordeon */
        $('.block_filters .filter_wrapper .filter_name').each(function () {
            $(this).click(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parent().find('.wrapper_options.active').removeClass('active');
                }
                else {
                    $('.block_filters .filter_name.active').removeClass('active');
                    $('.block_filters .wrapper_options.active').removeClass('active');
                    $(this).addClass('active');
                    $(this).parent().find('.wrapper_options').addClass('active');
                }
            });
        });
    }

    $("#searchComponent .title_search h1").on("click", function(){
        var menuShade = $('#shad_menu');
        var topSearch = $('.wrap_search_field');
        var searchInput = $('#search_home');
        topSearch.addClass("appear");
        topSearch.show();
        searchInput.focus();
        menuShade.addClass("actif_search");
    });
    
    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });
    
    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
        }
    });
});

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topProductAppear(elem) {
    var show_top = $('.cart_box',elem);
    if (!show_top.hasClass("appear") && $.trim(show_top.html())!='') {
        $(".cart_product_listing",elem).lionbars();
        show_top.addClass("appear");
        $('.connexion_top').removeClass("appear");
        $('#shad_menu').addClass("actif rollPanier");
    }
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topProductDesappear() {
    var show_top = $('.cart_box');
    if (show_top.hasClass("appear")) {
        show_top.removeClass("appear");
        $('#shad_menu').removeClass("actif rollPanier");
    }
}

function closeRecherche(){
    $(".wrap_search_field").fadeOut();
    $("body").removeClass("search");
    $("#trigger_recherche").css("opacity", "1");
    $("#shad_menu").removeClass("actif_search");
    if ($(window).scrollTop() <= 30) {
        $("#site_head_wrap").removeClass("sticky");
    }
}

$('#search_home').keypress(function (e) {
    var key = e.which;

    if(key == 13 && $('#search_home').val().length >= 3){
        closeRecherche();
    }
});


function btnLoaderTrigger(evt) {
    "use strict";

    var element = $(this);
    var loader = $(this).last(".loader");

    if (loader.length > 0) {
        // Hipay submit was completely blocked on desktop without this. Be careful to make a payment test if you remove the condition.
        if (element.attr('id') != 'hipay-3-submit-button' && !document.getElementsByClassName('store_locator_page')) {
            evt.preventDefault();
        }

        loader.removeAttr("style").parent().addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
        }, 1200);

        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.href = href;
        } else {
            console.error("Element does not have a href attribute");
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function() {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
    }
}

var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

var id_select = '';

// -----------------------------------------------------------------

// Définit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {

    "use strict";

    for (i = 0; i < elementIdArray.length; i++) {

        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInput && elementInput.type != 'hidden') {

            elementInput.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Définit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {

    "use strict";

    var elementInputDay, elementInputMonth, elementInputYear, elementLabel;

    for (i = 0; i < elementIdArray.length; i++) {

        elementInputDay = document.getElementById(elementIdArray[i] + 'J');
        elementInputMonth = document.getElementById(elementIdArray[i] + 'M');
        elementInputYear = document.getElementById(elementIdArray[i] + 'A');
        elementLabel = document.getElementById(elementIdArray[i] + 'Label');

        if (elementInputDay && elementInputDay.type != 'hidden'
            && elementInputMonth && elementInputMonth.type != 'hidden'
            && elementInputYear && elementInputYear.type != 'hidden') {

            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel) {

                elementLabel.className = 'labelForm';
            }
        }
    }
}

// Vérification d'un bouton radio
function verif_radio(elementID, fieldname) {

    "use strict";
    if (elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle') {

        elementID = 'clientCivilite';
    }

    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    var t_error = null;

    $(objElement).removeData('data-error-text').removeAttr('data-error-text');

    if (elementID) {

        $("input[name='" + elementID + "']").each(function (idx) {

            if ($(this).is(':checked') == true) {

                objElement.className = 'inputForm';
                trouve = true;
            }
        });

        if (!trouve) {

            if (elementID == 'clientCivilite') {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            } else {

                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }

            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        } else {

            $(objElement).removeClass('inputErr');
            $(objLabel).removeClass('labelErr');
        }
    }
    return true;
}

function verif_price(e, t) {

    "use strict";

    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"), document.getElementById("erreur_msg"));

    if ($(a).removeData("data-error-text").removeAttr("data-error-text"), "" == a.value) return t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1;

    var n = validate_price(a.value);

    return 0 == n ? (t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1) : (a.className = "inputForm", r.className = "labelForm", !0)
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e)
}

// Vérificartion d'une case coché
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != '97') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "MO" && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "TO" && idPays != '97' && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97ou98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if ((objCountryElement.value != "DO" && objCountryElement.value != "TO" && objCountryElement.value != "MO") && (idPays == '97' || (idPays == '98' && objCountryElement.value != "FR"))) {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objZipElement).removeClass('inputForm');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.') {
                point = point + 1;
            }
            if (test == ',') {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }

    }
    $(objElement).removeClass('inputErr');
    $(objElement).removeClass('labelErr');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID + 'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if (objElementHidden !== null) {

        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == '337') {

            if (elementID === 'clientTel') {

                $('#clientMobileHidden').val(value);
            } else if (elementID == 'telephone') {

                $('#mobileHidden').val(value);
            } else if (elementID == 'billtelephone') {

                $('#billmobileHidden').val(value);
            } else if (elementID == 'candidatTel') {

                $('#candidatTelHidden').val(value);
            }
        } else {
            if ($(objElement).intlTelInput("isValidNumber") !== false && (type === 0 || type === 2 || type === 3 || type === 6)) {

                $(objElementHidden).val(value);
            } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {

                if (elementID === 'clientTel') {

                    $('#clientMobileHidden').val(value);
                } else if (elementID == 'telephone') {

                    $('#mobileHidden').val(value);
                } else if (elementID == 'billtelephone') {

                    $('#billmobileHidden').val(value);
                } else if (elementID == 'candidatTel') {

                    $('#candidatTelHidden').val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {

            $(objElement).addClass('inputErr');

            return false;
        }

        $(objElement).removeClass('inputErr');

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {

        if ($(objElement).intlTelInput("isValidNumber") === false || ((is_mobile && $(objElement).intlTelInput("getNumberType") === 0)) || (is_mobile == false && $(objElement).intlTelInput("getNumberType") === 1)) {

            if (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname + '_mobile') + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_mobile&arg2=' + fieldname);
            } else {

                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_phone&arg2=' + fieldname);
            }

            return false;
        } else {

            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass('inputErr');

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        $(objElement).removeClass('inputForm');
        $(objLabel).removeClass('labelForm');
        return false;
    }
    $(objElement).addClass('inputForm');
    $(objLabel).addClass('labelForm');
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = decodeURIComponent("%C2%B0") + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; éééééiééééééé";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof (path_relative_root) != 'undefined')
        path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof (is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " + decodeURIComponent("%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 ");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        if (objElement.value.length < 2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee - 18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {


        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass('inputErr');
        } else {
            $(objElementJ).removeClass('inputErr');
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass('inputErr');
        } else {
            $(objElementM).removeClass('inputErr');
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass('inputErr');
        } else {
            $(objElementA).removeClass('inputErr');
        }
        if (!error) {
            $(objLabel).removeClass('labelErr');
            return true;
        } else {
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau) {
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && objForm.prodSousCateg_0.selectedIndex
            && !objForm.prodObjet_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval('objForm.prodObjet_' + x))
                    $errTMP = true;
                else if (!eval('objForm.prodObjet_' + x + '.selectedIndex'))
                    $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb + '&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (document.getElementById(field1).value != document.getElementById(field2).value) {
        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=' + txt_lang + '&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array('1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12');
    return array_closed_array.in_array(day + '/' + month);
}

function changeVisu(field, path, evt) {

    var new_img, target, current;

    if (evt) {

        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {

            if (elm !== target) {

                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {

        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {

            this.alt = field.alt;

            this.setAttribute('width', this.naturalWidth);
            this.setAttribute('height', this.naturalHeight);

            if (field.parentElement) {

                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
}

function getPos(obj) {
    var pos = {x: obj.offsetLeft || 0, y: obj.offsetTop || 0};
    while (obj = obj.offsetParent) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = 'none';
    document.getElementById(id).style.zIndex = '0';
}

var one_time = false;

function openInfobulle(id, type, elem) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css('display', 'none');
    if (!jQuery.browser.msie) $("#bloc_erreur").stop(false, true).fadeOut();
    if (!jQuery.browser.msie) $("#" + id).fadeIn(400);
    else document.getElementById(id).style.display = 'block';
    if (typeof (type) != 'undefined') {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if (elem.nodeName == 'SELECT') {
            id = elem.id.substr(0, elem.id.length - 1);
            if (id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost') {
                var txt_spe = document.getElementById(id + 'ErrErr').innerHTML;
                margin_spe_left = 300;
                if (id == 'clientPwdAuth')
                    margin_spe_top = 10;
                infos_alias
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(id + 'Err').innerHTML;
        } else {
            if (elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost') {
                if (document.getElementById(elem.id + 'ErrErr'))
                    var txt_spe = document.getElementById(elem.id + 'ErrErr').innerHTML;
                if (elem.id == 'mailLost')
                    margin_spe_left = -350;
                else
                    margin_spe_left = -340;
                if (elem.id == 'clientPwdAuth')
                    margin_spe_top = 41;
                else
                    margin_spe_top = 4;
            } else if (type == 'banque')
                var txt_spe = document.getElementById('infos_carte').innerHTML;
            else if (type == 'alias_banque')
                var txt_spe = document.getElementById('infos_alias').innerHTML;
            else
                var txt_spe = document.getElementById(elem.id + 'Err').innerHTML;
        }
        document.getElementById('erreur_msg').innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        if (type == 'banque') {
            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == 'alias_banque') {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
        document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';
        pos_left_margin = getPos(document.getElementById('content'));
        left_add = 0;
        if (document.getElementById('wrapper_anniversaire_jour1')) {
            left_add = document.getElementById('bloc_erreur').offsetWidth;
            left_add -= 35;
        }
        document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) + margin_spe_left + 27 - left_add) + 'px';
        if (type == 'banque' || type == 'alias_banque') {
            document.getElementById('bloc_erreur').style.width = '280px';
        } else {
            document.getElementById('bloc_erreur').style.width = '280px';
        }
        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}

function OverPopup(elem, msg, type, is_vp) {
    clearTimeout(timer);

    if (type == 'visible') {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").css('display', 'none');
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").stop(false, true).fadeOut();
            }
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'block';
            }
        } else {
            if (!document.getElementById('formAnniversaire') || (document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit')) {
                pos = getPos(elem);
                document.getElementById('erreur_msg').innerHTML = msg;
                if (!jQuery.browser.msie) {
                    $("#bloc_erreur").stop(true, true).fadeIn(400);
                } else {
                    document.getElementById('bloc_erreur').style.display = 'block';
                }
                document.getElementById('bloc_erreur').style.position = 'absolute';
                pos_left_margin = -13;
                var left_add = 17;
                if (document.getElementById('wrapper_anniversaire_jour1')) {
                    left_add = document.getElementById('bloc_erreur').offsetWidth;
                    left_add -= 35;
                }
                if (document.getElementById('formAnniversaire')) {
                    left_add = $('#erreur_msg').width() - 10;
                    $(".bottom_tooltip").attr('align', 'right');
                }
                document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) - pos_left_margin - left_add) + 1 + 'px';
                if (is_vp === 'cmpid_adwords') {
                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top: $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left: $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };
                    $('#bloc_erreur').remove();
                    $(elem).after(clone);
                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        'float': 'right',
                        'margin': '0 7px'
                    });
                    $('#bloc_erreur').css({
                        'position': 'absolute',
                        'top': c_pos.top,
                        'left': c_pos.left,
                        'z-index': '99999',
                        'opacity': '1'
                    });
                } else {
                    document.getElementById('bloc_erreur').style.top = (pos.y - document.getElementById('bloc_erreur').offsetHeight) + 'px';
                }
                if (elem.id == "departement_naissance") {
                    $('#bloc_erreur').css({"width": 180, "marginTop": 63, "marginLeft": -310});
                }
                document.getElementById('bloc_erreur').style.zIndex = '99999';
            }
        }
    } else {
        if (typeof (is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'none';
            }
            document.getElementById('bloc_erreur_vp').style.zIndex = '0';
        } else {
            if (!jQuery.browser.msie) {
                $("#bloc_erreur").stop(true, true).fadeOut(400);
            } else {
                document.getElementById('bloc_erreur').style.display = 'none';
            }
            document.getElementById('bloc_erreur').style.zIndex = '0';
        }
    }
}

/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */
function getStyle(obj, styleProp) {
    if (obj.currentStyle)
        return obj.currentStyle[styleProp];
    else if (window.getComputedStyle)
        return document.defaultView.getComputedStyle(obj, null).getPropertyValue(styleProp);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function filterSearch(id){
    if ($("#"+ id).hasClass('active')) {
        $("#"+ id).removeClass('active');
        $("#"+ id + " .wrapper_options").removeClass('active');
    }
    else {
        $('.block_filters .filter_wrapper.active').removeClass('active');
        $('.block_filters .filter_wrapper .wrapper_options.active').removeClass('active');
        $("#"+ id).addClass('active');
        $("#"+ id + " .wrapper_options").addClass('active');
    }
}

function triggerDropdown(elem, trigger, toDrop) {
    $(elem).toggleClass('actif');
    $(elem).find(toDrop).toggleClass('actif');
    $(".filters_container_wrapper").overlayScrollbars({});
}

/* showblocform */
function showBlocForm(id, type) {

    clearTimeout(timer);

    if ($('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block') {

        $('#bloc_erreur').css('display', 'none');
    }

    if (id == 'is_not_client') {

        $(".wrapper_login.left").addClass('actif');
        $(".wrapper_login.right").removeClass('actif');

        if ($("#bloc_lost_password").is(':visible')) {

            setTimeout(reloadBlocLogin, 100);
        }
    } else if (id == 'is_client') {

        $(".wrapper_login.left").removeClass('actif');
        $(".wrapper_login.right").addClass('actif');
    }
}

/* affichelostpassword */
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/* reloadbloclogin */
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function afficheLostPasswordTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#is_client_form").hide();
    $("#lost_password_top").css({display: "block", opacity: 0}).fadeIn(600);
}

function reloadBlocLoginTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

function show_lightbox_3xcb() {
    // Commenté et return car view masterisée
    return
}

function close_lightbox_3xcb() {
    closeMultiShad('popup_cofidis3cb');
}

function in_array(needle, haystack, strict) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']);
    // *     returns 1: key
    var rKey = -1, strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

$.fn.recalImg = function (img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({position: "", height: "", width: "", left: "", top: "", marginTop: "", marginLeft: ""});
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({position: "relative"});
    img.css({position: "absolute"});
    if ((heightImg > heightObj) || (widthImg > widthObj)) {
        if (widthImg > heightImg) {
            img.css({height: heightObj});
        } else if (widthImg <= heightImg) {
            img.css({width: widthObj});
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({left: 0, top: 0});
        } else if (widthImg <= heightImg) {
            img.css({top: 0, left: 0});
        }
    } else {
        img.css({top: "0", left: "0"});
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    } else {
        $(c).css({color: "#000", fontStyle: "normal"});
    }
    c.text($('option:selected', s).text());
    if ($('option:selected', s).val() == "") {
        $(c).css({color: "#aaa", fontStyle: "italic"});
    }
}

$.fn.cbForm = function () {
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({opacity: "1"});
    fs.hover(function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({opacity: "0.5"});
        }
    }, function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({opacity: "1"});
        }
    });
    fs.click(function () {
        if (t.hasClass('active_bloc')) {
            fs.removeClass("activ");
            fs.css({opacity: "1"});
            $(this).addClass("activ");
            $(this).css({opacity: "0.5"});
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr('selected', 'selected');
            p = $('.type_carte option:selected', t);
            c = $('.clone', $('.type_carte', t).parent('.selectStyled'));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({color: "#aaa", fontStyle: "italic"});
            } else {
                $(c).css({color: "#000", fontStyle: "normal"});
            }
        }
    });
    $('.type_carte', t).change(function () {
        tcv = $('option:selected', this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({opacity: "1"});
        if (ctcv != "") {
            $('.form_select#' + ctcv).addClass("activ");
            $('.form_select#' + ctcv).css({opacity: "1"});
        }
    });
}

function showrecuppanierlightbox() {
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display = "block";
    modbox.style.display = "block";
    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, 'click', closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad, 'click', closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').attachEvent("on" + event, name_function);
    else document.getElementById('shad').addEventListener(event, name_function, false);
}

function delEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').detachEvent("on" + event, name_function);
    else document.getElementById('shad').removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés

     if (size > 4000000) {
        alertV2('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alertV2('Format autoris&eacute; : jpg, png, pdf');
        error = true;
    }

    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {
        if (id_input == 1) {
            $('#field_send').removeClass('cache');
        }
        if (field != 'filepj') {
            var params = (typeof (string) != 'undefined') ? ',"' + string + '"' : '';
            var style = (typeof (string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';
            $('#' + field + id_input).after("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput(" + next_file + params + ")' /> ");
            $(input).attr('value', next_file);
        } else {
            $('#' + field + id_input).parent().find(".file_msg p").text(file.name);
        }
    }
}

function uploadfile() {//fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $('#message_file').val();
    var fsize = $('#message_file')[0].files[0].size; //get file size
    var ftype = $('#message_file')[0].files[0].type; // get file type
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autorisés
    if (fsize > 4000000) {
        alertV2('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alertV2('Format autorise : jpg, png, pdf');
        error = true;
    }
    if (error) {
        $('#message_file').val('');
    } else {

    }
}


function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {lang: locale, url: uri, site: site},
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/sunco-paris.html') {
                    force = '?force';
                }

                location.href = data + force;
            }
        }
    });
}

$.fn.selectChangeAdr = function () {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function (response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({display: 'none'}).slideDown();
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place('delivery');
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function () {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function () {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("setCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("setCountry", country_select);
                        }
                    });

                } else {
                    country_select = "fr";
                }
            }
        });
    }
};

(function () {

    "use strict";

    var stickyCnt = 0;

    function initStickies() {

        var stickies = document.getElementsByClassName("sticky");

        Array.prototype.slice.call(stickies).forEach(function (sticky) {

            if (sticky.id !== "site_head_wrap") {

                if (sticky.id === "") {

                    stickyCnt += 1;
                    sticky.id = "sticky-" + stickyCnt;
                }

                sticky = new ScrollLock(sticky.id);
            }
        });
    }

    $(initStickies);
}());

$(function () {
    $(document).on("click", ".button", btnLoaderTrigger);
    
    'use strict';
    $("#cart_list").lionbars();
    $("#cart_top").on('mouseenter touchstart', function (evt) {
        $("#cart_list").lionbars();
    });

    $(".store_locator").not('#stepStoreList').lionbars();
    $(".lightbox#available-in-store").on('mouseenter touchstart', function (evt) {
        $(".store_locator").not('#stepStoreList').lionbars();
    });

});

/* Enables hover state on any element when using a touch based device */
(function () {

    function nevermind() {

        return;
    }

    document.body.addEventListener("click", nevermind);
}());

/**
 * @description Recovers stock sizes by color and their stock
 *
 * @since 2022-11-04
 */
function setAvailableSizes() {
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    if (
        (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false && enable_gbe_sfs == false) ||
        (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false && enable_gbe_sfs == false)
    ) {
        data.warehouse_only = 1;
    }

    var elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result && res.result[produit_id] != undefined && res.result[produit_id][elem_id] != undefined) {
                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;
                var has_stock = res.result[produit_id]['stock_quantite'] > 0;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                        is_disabled = !sizes_tab[size_id];
                        size_box.siblings();

                        if (!is_disabled) {
                            size_box.removeClass('disabled');
                            $('#label_' + size_id).removeClass('disabled');
                        } else {
                            size_box.addClass('disabled');
                            $('#label_' + size_id).addClass('disabled');
                        }
                    }
                }

                if (has_stock) {
                    $("#bloc_add_basket").show();
                    $("#bloc_add_alert").hide();
                } else {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                }
            }
        }
    });
}

/* PROMO BAR */
$(function () {
    $('.promo_swiper .swiper-slide').each(function () {
        if($(this).css('background-color') == 'rgb(0, 0, 0)'){
            $(this).find('.navpromo').addClass('white');
        }
    });
});
/* FIN PROMO BAR */

$.fn.initAssocBloc = function() {

    var element = $(this);

    function displayItem(element) {
        var list = [];

        $("a", element).each(function(index) {
            list.push($(this));
        });

        list = shuffle(list);

        var length = list.length;
        if (length > 4) {
            length = 4;
        }
        element.html('');
        for (var i = 0 ; i < length ; i++) {
            element.append(list[i]);
        }
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    displayItem(element);

};

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === 'popup_numero_retour') {
        setTimeout(function (){
            $('#return_form_wrapper').overlayScrollbars({});
        }, 250);
    }

    if (id === 'selection-offer') {
        setTimeout(function (){
            $('#selection-offer-content').overlayScrollbars({});
        }, 250);

        // SW-13166 Trigger click when there is only one product to select
        if($('.js-form-selection-offer').length == 1) {
            $('.js-form-selection-offer').trigger('click');
        }
    }

    if(id === "zoomBox") {
        $('body').css('position','fixed');
        $('#zoom_box_wrapper .swiper-slide').on('click', function () {
            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {

            buyNowSwiper = new Swiper("#buyNowSwiper", {

                width: 260,
                height: 390,
                sliderPerView: 1,
                navigation: {
                    nextEl: '.modboxNext',
                    prevEl: '.modboxPrev',
                },
            });
        }, 50);
    }

    if(id === "sizeguideBox"){
        $(".sizeguide_table").each(function() {
            window.setTimeout(function () {
                $(".sizeguide_table").lionbars();
            }, 300);
        });
    }

    if(id == "selection-offer") {

        setTimeout(function () {

            $("#selection-offer-content").lionbars();
            var color_list = new Swiper('.color_list', {

                spaceBetween: 15,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextColour',
                    prevEl: '.prevColour',
                },
            });

            var size_list = new Swiper('.size_list', {

                spaceBetween: 15,
                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextSize',
                    prevEl: '.prevSize',
                },
            });
        }, 500);
    }

    if (id == 'popup_cofidis3cb') {
        // COFIDIS FP
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }
}

function closeMultiShad(id) {

    $('#shad').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).fadeOut("slow").queue(function () {

        if(id === "zoomBox") {
            $('body').css('position','');
        }

        if($('#shad').is(":visible") && ($('body').hasClass('category') || $('body').hasClass('homepage') || $('body').hasClass('search_page_on')) && id === 'sizeguideBox'){

            $('#shad').css('display', 'block');

        } else if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {

                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {

                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });
}

$(function() {
    infosObligatoires();
});

/*
Page Mon compte Informations
Ajout texte champs obligatoires - Form login
*/
function infosObligatoires() {
    var loginFrm = $('.wrapper_content_mes_infos form[name="login"]');
    var lastFrmLn = loginFrm.find('.w-form-line').last();
    var text = '<p class="infos-obligatoires">' + Translator.translate('infos_obligatoires') + '</p>';

    if(lastFrmLn.length) {
        lastFrmLn.before(text);
    }
}

// Translation text function
(function() {
    'use strict';

    function parseArgs(args) {
        var obj = {},
            inc = 0,
            len = args.length;
        for (inc; inc < len; inc += 1) {
            obj['arg' + inc] = args[inc];
        }
        return obj;
    }

    function translate() {
        var o_data = parseArgs(arguments),
            s_resp = arguments[0];
        $.ajax({
            url: path_relative_root + 'ajax_sprintf.php',
            type: 'get',
            data: o_data,
            async: false,
            success: function(res) { s_resp = res }
        });
        return s_resp;
    }
    window.translate = translate;
}());

$(function () {
    /**
     * Toggle
     * Code avantage
     * page panier
     */
    $(".coupon .intro_code").on('click', function(){
        $(".coupon .w-coupon-form").slideToggle();

    });
});

/* pour les tableaux du guide des tailles */
function fadeTaille(id, nbelem) {
    $("#content_quide_" + id).css('display, none');

    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#content_quide_" + i).css('display') == 'block') {
            $("#content_quide_" + i).hide();
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {
        $("#content_quide_" + id).fadeIn(600);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
        $("#content_quide_" + id).css('display', 'block');
        window.setTimeout(function () {
            $(".sizeguide_table").lionbars();
        }, 100);
    } else {
        $("#content_quide_" + id).hide();
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }
}

// Retire le colon(:) dans le texte "Par PayPal :"
$(function () {
    var bankDiv = $('#bank_div');
    var bankTitle = bankDiv.find('.wrapper_bloc_banque.paypal .bank_title span');
    var bankTitleTxt = bankTitle.text();

    if(bankTitle.length) {
        bankTitle.html(bankTitleTxt.replace(/:/g, ''));
    }

});

// Fiche produit zoomBox
$(function () {
    if ($("#zoomBox.lightbox").css('display') == 'block'){
        $('body').css('overflow', 'hidden')
    }
});

/*# FIX SIDE NAV #*/
function updateCurrentNav() {
    var scrollAmount = $(document).scrollTop();
    var windowHeight = $(window).innerHeight();

    $(".productVisu").each(function() {
        var sectionBlocId = $(this).attr("id");
        var sectionBlocHeight = $("#" + sectionBlocId).innerHeight();
        var sectionBlocPosTop = $("#" + sectionBlocId).offset().top;

        if (
            sectionBlocPosTop - scrollAmount < windowHeight / 2 &&
            sectionBlocPosTop > scrollAmount - sectionBlocHeight / 2
        ) {
            $("." + sectionBlocId).addClass("actif");
            $("#" + sectionBlocId).addClass("actif");
        } else {
            $("." + sectionBlocId).removeClass("actif");
            $("#" + sectionBlocId).removeClass("actif");
        }
    });
}

$(function() {
    window.addEventListener("load", updateCurrentNav);
    window.addEventListener("scroll", updateCurrentNav);
});


if ($('.satellite_store_details .wrapper_store .images').length === 0) {
    $('.satellite_store_details ._wrapper_main').css({'justify-content' : 'flex-start','max-width': '840px'});
};


function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
}

if (window.matchMedia('(min-width: 1024px)').matches) {
    var e = document.getElementsByClassName("phone");
    for (i = 0; i < e.length; i++)
        e[i].setAttribute("x-ms-format-detection", "none");
}


$(function() {

    /* Input file -- attachment */

    var $input = $('#filepj1'),
        $label   = $input.prev( 'label.ajout_pj_label' ),
        labelVal = $label.text().replace(/\s:/i, ""),
        closePJ = $input.closest('.file-input-row').next('.file-list').find('.rowList .closePj#reset_file1');

    // set PJ label text
    $label.html(labelVal);

    $input.on( 'change', function( e )
    {
        var fileName = '';

        closePJ.on('click', function(e) {
            $label.html( labelVal );
        });

        if( e.target.value ) {
            fileName = e.target.value.split( '\\' ).pop();
            $label.addClass('has_pj');
        }
        else {
            if($label.hasClass('has_pj')) {
                $label.removeClass('has_pj');
            }
        }

        if( fileName )
            $label.html( fileName );
        else
            $label.html( labelVal );
    });

    if($('#cart_total .bill_line.total').length && !$('body').hasClass('customer')){
        $('.bill_line.delay_information').insertAfter($("#cart_total .bill_line.total")).show();
    }

    // Masquer l'entrée A propos au scroll

    var menuApropos = $('.menu_categ_wrapper .menu_static');
    $(window).scroll(function() {

        if ($(this).scrollTop() > 0) {
            menuApropos.fadeOut(1000);
        } else {
            menuApropos.fadeIn(1000);
        }
    });

    if ($('.tgCms').length) {

        var journalModuleSwiper = new Swiper( ".tgCms.swiper", {
            slidesPerView: 4,
            loop: false,
            spaceBetween: 12,
            navigation: {
                nextEl: ".cmstg-button-next",
                prevEl: ".cmstg-button-prev",
            },
        } );
    }

    if ($('#articles_list_swiper').length) {
        var swiperArticleModule = new Swiper( "#articles_list_swiper", {
            slidesPerView: 2,
            loop: false,
            spaceBetween: 30,
            navigation: {
                nextEl: ".articles-list-swiper-button-next",
                prevEl: ".articles-list-swiper-button-prev",
            },
        });
    }

    var container_other_information = $('.cart.fr .main_wrapper #container_other_information');

    if (container_other_information.length) {
        if ($(".cart_main_table.wishlist.dropper .cart_product_line").length > 0) {
            $(container_other_information).insertAfter("#right_col_sticky .bloc_question");
            container_other_information.addClass('with_wishlist');
            $('.cart.fr .main_wrapper #container_other_information .container_logo_other_information').addClass('with_wishlist');
            $(container_other_information,'.with_wishlist').css('display', 'block');
        } else {
            container_other_information.css('display', 'flex');
        }

        scrollToDonationBox();
    }
});

function scrollToDonationBox() {
    if ($('.cart.fr .main_wrapper #container_other_information').length ) {
        $(".donation_savoir_plus").on('click tap touch', function () {
            // Calculate the scroll position to center the container
            var screenHeight = $(window).height();
            var containerHeight = $("#container_other_information").height();
            var scrollTo = (containerHeight - screenHeight) / 2;

            // Scroll to the calculated position with smooth animation
            $('html, body').animate({
                scrollTop: $("#container_other_information").offset().top + scrollTo
            }, 1000);
        });
    }
}

$(".homepage .home-module-cta a").each(function(){
    if (!$(this).text().trim().length) {
        $(this).css('border', 'transparent').hide();
    } else {
        $(this).css('opacity', '1');
    }
});

$(".homepage .home-module-cta").each(function(){

    if (!$(this).text().trim().length) {
        $(this).find('.tiret_cta').css('background', 'transparent').hide();
    } else {
        $(this).find('.tiret_cta').css('opacity', '1');
    }
});

/* Page cart */
$("body.cart.step_1 #cart_total .bill_line.shipping_information").insertAfter("body.cart.step_1 #cart_total .bill_line.total");

/* page livraison */
$("body.cart.step_2 .order_recap .bill_line.shipping_information").insertAfter("body.cart.step_2 .order_recap .order_total.subtotal");

// Fade in filter when page is ready
// /!\To test if global-e is activated we need to wait after all elements are loaded
$(window).on('load', function () {
    if ($('.block_filters').length) {
        if ($('.block_filters').hasClass('faded')) {
            $('.block_filters').removeClass('faded');
        }
    }
    if (typeof GEM_Services != "undefined" && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
        var filterSizes = $('#block_filters #filter_sizes');
        if (GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE == true) {
            filterSizes.remove();
        } else {
            filterSizes.show();
        }
    }
});

/**
 * Function to manage the "Read more" button on the department page
 */
let scrollText = function () {
    let elementSeoText = $('.textSeo_bloc_description');
    if (elementSeoText.hasClass('see_more')) {
        elementSeoText.removeClass('see_more').addClass('see_less');
    } else {
        elementSeoText.removeClass('see_less').addClass('see_more');
    }
}