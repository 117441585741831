var save_id = 0;
var cpt_save = 0;
var from_range = false;  //for wishlist

function addEvent(elem,event,name_function) {

    if(document.all) elem.attachEvent("on"+event,name_function);
    else elem.addEventListener(event,name_function,false);

}

function delEvent(elem,event,name_function) {

    if(document.all) elem.detachEvent("on"+event,name_function);
    else elem.removeEventListener(event,name_function,false);

}

// empeche les evenements de remonter dans l'arbre

function doNothing(e) {

if (document.all) {
  if (!e) var e = window.event;
  e.cancelBubble = true;
 }
 else {
    e.stopPropagation();
 }
 return false;
}
// popup guide des tailles
function showsizeguide(){
    var elname = 'sizeguidebox';

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');
    modbox.style.position = "absolute";
    shade.style.display="block";

    addEvent(shade,'click',closesizeguide);

    addEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);

    modbox.style.display="block";

    window.scrollTo(0,actualposition);
}

// popup alerte taille
function showAlertSizeBox(taille){

    var alertSizeBoxText = document.getElementById('alertSizeBoxText');
    alertSizeBoxText.innerHTML = taille;
    openMultiShad('alertSizeBox');
}

function closesizeguide() {
    var elname = 'sizeguidebox';
    var shade = document.getElementById('shade');
    var modbox = document.getElementById(elname);
    modbox.style.display="";

    delEvent(shade,'click',closesizeguide);
    delEvent(modbox,'click',closesizeguide);
    delEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);
    closemodal();
}

function showmodal(produit_id){
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById('modbox');
    var cmodbox = document.getElementById('cmodbox');
    var panmodbox = document.getElementById('panmodbox');
    var shade = document.getElementById('shade');
    document.body.style.overflow='hidden';

    cmodbox.innerHTML='';
    panmodbox.innerHTML = '';

    modbox.style.marginTop = (actualposition-260)+"px";
    shade.style.display="block";
    modbox.style.display="block";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    window.scrollTo(0,actualposition);
}

var addToCartTmpl;

function buildItemAddedModbox(id_produit, is_not_produit) {

    var form = document.getElementById('prod_info_' + id_produit);
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'closeMultiShad(\'modbox\')';

    if (document.getElementById('nbr_visu_0')) {

        var itm_vis = $('img', '#nbr_visu_0').attr('src').replace('/mini/', '/large/');
    }

    var itm_name = $('#itm_name', form).get(0).textContent.trim();

    if ($('#itm_feat', form).length > 0) {

        var itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    var itm_price = $(".pricetag:first", form).html();

    if ($(".pricetag:first + .pricetag", form).length) {

        var itm_fullprice = $(".pricetag:first + .pricetag", form).html();
    }

    if (form) {

        var itm_length = parseFloat(form.elements.namedItem('qteProd').value);
        var itm_color = form.elements.namedItem('couleurProd').value;
        var itm_size = form.elements.namedItem('tailleProd').value;

        if (form.getValue('produit_lot_nb', parseFloat) > 1 || itm_length > 1) {

            itm_length += ' ' + Translator.translate('items');
        } else {

            itm_length += ' ' + Translator.translate('item');
        }

        if (itm_length == 1) {

            itm_length += ' ' + Translator.translate('added_to_cart_sn');
        } else if (form.getValue('produit_lot_nb') == '1') {

            itm_length += ' ' + Translator.translate('added_to_cart_pl');
        }

        itm_color = $("input[name=itm_color]", form).filter("[value=" + itm_color + "]").next("label").attr("title").trim();
        itm_size = $("input[name=itm_size]", form).filter("[value=" + itm_size + "]").next("label").attr("title").trim();
    }

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_vis: itm_vis,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_fullprice: itm_fullprice,
        itm_length: itm_length,
        itm_color: itm_color,
        itm_size: itm_size
    });
    // Si c'est la premiere fois on crée l'image et ensuite on l'insere a la suite
    // C'est pour éviter que le serveur appel une image qui n'existe pas
    if ( $('#modbox_visu').length > 0 ) {
        var span = $('#modbox_visu');
        // Creation de l'image
        var img = $('<img>', {
            src : span.data('src'),
            alt : span.data('alt')
        });
        $('#modbox_visu').after(img);
        $('#modbox_visu').remove();
    }


}

/**
 * Lancer de l'ajout au panier
 * @param products
 * @param type
 * @param root
 * @param id_produit
 * @param is_not_produit
 */
function newshowmodal(products, type, root, id_produit, is_not_produit)
{
    topProductAppear();
    window.setTimeout(function () {

        topProductDesappear()
    }, 5000);
}

function showsendfriendmodal(product_id, path_web, type) {

    var elname = (type === 'look_basket') ? 'modbox' : 'sendfriendbox';
    var actualposition = document.documentElement.scrollTop ;

    if (type !== 'look_basket') {

        document.getElementById('product_sendfriend_nok').innerHTML = '';
        document.getElementById('product_sendfriend_ok').innerHTML = '';
        document.getElementById('product_sendfriend_nok').style.display = 'none';
        document.getElementById('product_sendfriend_ok').style.display = 'none';

        openMultiShad('sendfriendbox');

    } else {

        openMultiShad(elname);
        reloadLookparts();
        $('.loader', '#bloc_add_basket').hide();
    }
}

function closesendfriendmodal() {

    closeMultiShad('sendfriendbox');

    document.getElementById('sendfriend_alert_mail').style.display="none";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }

    }
}

function closesendfriendmodalfromconfirm() {

    closeMultiShad('sendfriendbox');

    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_alert_mail').innerHTML="";
    document.getElementById('sendfriend_alert_mail').style.display="none";

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    var form_id = form_elm.getAttribute('id');
    var fk_produit_id = $("#" +  form_id).find('.prodSize').filter(':checked').data('produitid');

    if(!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {

        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, fk_produit_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, fk_produit_id) {

    var id = '';
    var prix_id = 'prixU';

    if (itm_rr_id === undefined) {
        itm_rr_id = '';
    }

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var send_produit_id = produit_id;
    if (typeof fk_produit_id !== 'undefined' && fk_produit_id != '') {
        send_produit_id = fk_produit_id;
    }

    var prix = '';
    if (document.getElementById(prix_id)) {
        prix = document.getElementById(prix_id).value;
    }

    var array_panier = newUpdatePanier(send_produit_id, suff, panier_id, prix, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);

            }
            pushIntoDatalayer(dl_objet);
        }

        if (typeof dataLayer_v3 !== 'undefined' ) {
            if (array_panier.length >= 0 && array_panier[6] !== undefined) {
                var dlv3_objet = JSON.parse(array_panier[6]);
                DataLayerTrigger.addToCart(dlv3_objet['items'], dlv3_objet['actionField'], is_achat_express);
            }
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);
        $("#cart_list").lionbars();
        $( "#cart_top" ).on('mouseenter touchstart', function(evt) {
            $("#cart_list").lionbars();
        });

        $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function updateZoomedImg(src) {

    $('.main_vis img', this).attr('src', src);
}

function updateSrc(path_web,dest) {
    var tmp_string_large = document.getElementById('img_large').src;
    tmp_string = tmp_string_large.replace(/medium/,"large");

    document.getElementById('img_large').setAttribute('onclick','zoomImg(\''+tmp_string+'\',\''+path_web+'\')');
    document.getElementById('loupe').href = 'javascript:zoomImg(\''+tmp_string+'\',\''+path_web+'\');';
    $(".zoomLens").css({backgroundImage : 'url("'+tmp_string_large+'")' });

    if(dest=='vue') {

        var i = 0;
        var trouve = false;
        while (i<tab_js.length && !trouve) {

            trouve = (tab_js[i][0].substring(tab_js[i][0].lastIndexOf('/')+1)==tmp_string.substring(tmp_string.lastIndexOf('/')+1));
            i++;

        }

        cpt_img = (i-1);

    }
}

function productSendFriendForm(path_web, request_uri) {

    document.getElementById("product_sendfriend_ok").style.display = 'block';
    document.getElementById("product_sendfriend_nok").style.display = 'block';

    if ( document.getElementById('sendFriendToMails') && document.getElementById('sendFriendToMessage') && document.getElementById('sendFriendYourName') && document.getElementById('sendFriendYourMail') && document.getElementById('sendFriendCopy') ) {
        document.getElementById('sendFriendToMails').style.border="";
        document.getElementById('sendFriendToMessage').style.border="";
        document.getElementById('sendFriendYourName').style.border="";
        document.getElementById('sendFriendYourMail').style.border="";

        var token = document.getElementById('token_security_sendfriend').value;
        var captcha = document.getElementById('g-recaptcha-response').value;

        var params_add = '';
        if(document.getElementById("produit_id")) {
            params_add +=  '&product_id='+document.getElementById("produit_id").value;
        }

        $.ajax({
            url: path_web + 'ajax_send_friend_form.php',
            type: 'POST',
            data: 'g-recaptcha-response=' + captcha + '&token_security=' + token + '&url=' + request_uri + '&to_mails=' + document.getElementById('sendFriendToMails').value + '&to_message=' + document.getElementById('sendFriendToMessage').value.replace(/\n/g,'<br>') + '&your_name=' + document.getElementById('sendFriendYourName').value + '&your_mail=' + document.getElementById('sendFriendYourMail').value + '&copy=' + document.getElementById('sendFriendCopy').checked + params_add
        }).done(function(response) {

            var content_id = 'product_sendfriend_nok',
                content = '';

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div style="font-size:14px;font-weight:bold;font-family:Arial; color:#222;text-align:center;margin-bottom:10px;margin-top:15px;font-family: arial;">'+ajax_file(path_web+'ajax_sprintf.php?arg1='+sing)+'</div>';
                content += '<p style=\"margin-top: 0px;margin-bottom: 30px;padding:0;text-align:center;\">'+s_adresse+'</p>';

                content_id = "sendfriend_alert_mail";

                document.getElementById('product_sendfriend_nok').innerHTML = "";
                document.getElementById('sendfriend_form_to').style.display = "none";
                document.getElementById('sendfriend_form_froms').style.display = "none";
                document.getElementById('sendfriend_form_buttons').style.display = "none";
                document.forms["sendfriendform"].reset();

                $(".form_submit.loading", "#sendfriendform").removeClass("loading");
            } else {

                $('#sendFriendToMails').removeClass('inputErr');
                $('#sendFriendToMessage').removeClass('inputErr');
                $('#sendFriendYourName').removeClass('inputErr');
                $('#sendFriendYourMail').removeClass('inputErr');

                if ($('#sendFriendToMessage').val() == '')
                    $('#sendFriendToMessage').addClass('inputErr');
                if ($('#sendFriendYourName').val() == '')
                    $('#sendFriendYourName').addClass('inputErr');
                if ($('#sendFriendYourMail').val() == '')
                    $('#sendFriendYourMail').addClass('inputErr');


                content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=error_fields') + "</p>";

                if (response == '1' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_no_mail_friends') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '2' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '6' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_no_my_mail') + "</p>";
                    $('#sendFriendYourMail').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == '7' ) {

                    content = "<img src=\""+path_relative_root+"img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                    $('#sendFriendToMails').addClass('inputErr');
                    $('td.labelForm').addClass('labelErr');

                } else if (response == 'token_error' ) {

                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_web+'ajax_sprintf.php?arg1=error_token_security') + "</p>";

                } else if (response == '3' || response == '4' || response == '5') {
                    $('#sendFriendToMessage').addClass('inputErr');
                    $('#sendFriendYourName').addClass('inputErr');
                } else {

                    var s_adresse = response.substr(3);
                    var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';

                    content = '<div class="msg_poperror">' + Translator.translate(sing) + '</div>';
                    content_id = "sendfriend_alert_mail";
                    content +=
                        '<div class="form_submit">' +
                            '<a class="button primary" href="#" onclick="closesendfriendmodalfromconfirm()">' +
                                '<span>' + Translator.translate("ok") + '</span>' +
                            '</a>' +
                        '<div>';

                    document.getElementById('product_sendfriend_nok').innerHTML = "";
                    document.getElementById('sendfriend_form_to').style.display = "none";
                    document.getElementById('sendfriend_form_froms').style.display = "none";
                    document.getElementById('sendfriend_form_buttons').style.display = "none";

                    document.getElementById('sendFriendToMails').value = "";
                    document.getElementById('sendFriendToMessage').value = "";
                    document.getElementById('sendFriendYourName').value = "";
                    document.getElementById('sendFriendYourMail').value = "";

                    $(".form_submit.loading", "#sendfriendform").removeClass("loading");
                }
            }

            document.getElementById(content_id).innerHTML = content;
            document.getElementById(content_id).style.display = 'block';
            $('#sendfriend_form_buttons').removeClass('loading');

        });
    }

    return false;
}

function fbs_click( site, params ){

    if ( params == '' ){
        params = location.href;
    }

    switch ( site ){
        case 'fb':
            window.open('http://www.facebook.com/share.php?u='+encodeURIComponent(params)+'&t=');
        break;
        case 'tw':
            window.open('https://twitter.com/share?url='+params);
            _gaq.push(['_trackSocial', 'twitter', 'Tweet', params]);
        break;
        case 'insta':
            window.open('https://www.instagram.com/vanessabruno/');
            break;
        case 'pin':
            window.open('http://pinterest.com/pin/create/button/?'+params);

            var url = params.split('&');
            url = url[0].split('=');
            url = decodeURIComponent( url[1] );
        break;
    }
    return false;
}

/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alertV2(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
            $('.btn_container.alert_stock').slideDown('fast');
        }

        if (iserrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
            $('.btn_container.alert_stock').slideDown('fast');
        }
    } else {
        $('.btn_container.alert_stock').slideUp('fast');
        $('.bloc_add_alert_form').slideDown('fast');
    }
}

/**
 * Sends a mail alert for stock.
 *
 * @param {string} type_alert - The type of alert.
 * @param {object} form - The form object.
 * @param {string} itm_rr_id - The itm_rr_id value.
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var elem = '';

    if (is_achat_express) {
        elem = '_' + id;
        if (itm_rr_id) {
            elem += itm_rr_id;
        }
    }

    var $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val();

    var inputEmail = form.elements['mail_alerte_stock'];
    var inputEmailBis = document.getElementsByClassName('expressAlertStock');
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputEmail.value === '' || !inputEmail.value.match(mailformat)) {
        $(inputEmail).addClass('inputErr');
        $(inputEmailBis).addClass('inputErr1');

    } else {
        var bloc_alert = $('#bloc_add_alert' + elem);
        var isOperatedByGlobalE = false;
        if (typeof GEM_Services != "undefined"  && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
            isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
        } else if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined) {
            isOperatedByGlobalE = GEP.isOperatedByGlobale;
        }

        if (isOperatedByGlobalE === true && !enable_gbe_sfs) {
            type_alert = "stock_gbe";
        }

        $.ajax({
            type : 'post',
            url : path_relative_root + create_link('ajax_mail_alert_stock'),
            data : {
                email : inputEmail.value,
                produit_id : $produit_id,
                taille_id : $taille_id,
                couleur_id : $couleur_id,
                type_alert : type_alert
            },
            success : function(response) {
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    "event": "alert_outOfStock",
                    "produit_id" : $produit_id,
                });
                $('.bloc_add_alert_form', bloc_alert).hide();
                $('.bloc_add_alert_erreur', bloc_alert).hide();
                $('.bloc_add_alert_confirmation', bloc_alert).fadeIn();
                $(inputEmailBis).addClass('success1');
            },
            fail : function () {

                $('.bloc_add_alert_confirmation', bloc_alert).fadeOut();
                $('.bloc_add_alert_erreur', bloc_alert).show();
            }
        });
    }
}

function closeAlerteStock(id) {
    var elem = '_' + id;
    var prodInfoElt = $('#prod_info' + elem);
    var blocAlert = $('#bloc_add_alert' + elem);
    blocAlert.hide();
    // On decoche la taille
    $('input[name="itm_size"]:checked', prodInfoElt).prop('checked', false);
}

//Description FP
$(document).on("click", ".product_detail_wrapper .product_detail_title", function(e) {
    'use strict';

    //hide all span
    var elt = $(this);
    var detailLabel = elt.attr("data-detail-label");

    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
        if (detailLabel !== undefined && detailLabel !== '') {

            TriggerProductDetailClick(detailLabel);
        }
    }

    $(elt).siblings('.product_detail_content').slideToggle();
    $(this, elt).toggleClass("active");

});

/*Zoom sliders*/

function zoomImg(slide) {
    $('#zoom_box_wrapper').css('opacity', 0);
    if($(".productLightboxVisu").length > 0) {
        $(".productLightboxVisu img").on('click touch', function(){
            closeMultiShad('zoomBox');
        });
    }
    if($("#thumbs_scroller").length > 0) {
        setTimeout(function(){
            // Get thumb slides from FP
            var getThumbSlides = $("#visual_scroller .swiper-wrapper").html();

            // Empty old thumbs in zoom
            $("#thumbs_scroller .swiper-wrapper").html('');

            // Updating thumbs in zoom
            $("#thumbs_scroller .swiper-wrapper").html(getThumbSlides);

            // Adding onclick on slides for main img update
            $("#thumbs_scroller .swiper-slide").each(function (i) {
                $(this).attr('onclick', 'zoomImg('+ i +')');
            });
        }, 100);

        var zoomThumbs = new Swiper('#thumbs_scroller', {
            direction: 'vertical',
            slidesPerView: 4,
            initialSlide: slide,
            observer: true,
            observeParents: true,
            mousewheel: true,
            navigation: {
                nextEl: '.thumbsNext',
                prevEl: '.thumbsPrev',
            },
        });

        $(".productVisu").each(function (index) {
            $(this).removeClass('active_slide');
        });

        $(".productVisu#nbr_visu_" + slide).addClass('active_slide');

        // Change thumbs img format
        $('#thumbs_scroller img').each(function () {
            if(typeof($(this).attr('src'))!='undefined') {
                $(this).attr('src',$(this).attr('src').replace('large', 'mini'));
            }
        });

        var src = $("#visual_scroller .productVisu#nbr_visu_" + slide + " img").attr('src');
            src = src.replace("mini", "large");
            $('#zoom_box_wrapper img.img_zoom').attr('src', src);
    }

    window.setTimeout(function () {
        $('#zoom_box_wrapper').animate({opacity: 1}, 650);
    }, 350);

    openMultiShad('zoomBox');
}

jQuery(document).ready(function(){
    jQuery('.scrollbar-external').scrollbar({
        "autoScrollSize": false,
        "scrolly": $('.external-scroll_y')
    });
});

/***********************
text Reservation*******/
function initFPElements() {
    if($("#gondole_portez").length > 0) {

        var productThumbs = new Swiper('#gondole_portez', {
            loop: false, // Obliger d'être à false pour que l'achat express v2 fonctionne
            mousewheel: false,
            slidesPerView: 3,
            spaceBetween: 5,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.show_portez-next',
                prevEl: '.show_portez-prev',
            },
        });
    }

    if($("#gondole_also_like").length > 0) {

        var productThumbs = new Swiper('#gondole_also_like', {
            loop: false, // Obliger d'être à false pour que l'achat express v2 fonctionne
            mousewheel: false,
            slidesPerView: 4,
            spaceBetween: 5,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.gondole_also_like-next',
                prevEl: '.gondole_also_like-prev',
            },
        });
    }
}

$(document).ready(function () {
    initFPElements();

    setTimeout(function () {
        $(".store_locator").not('#stepStoreList').lionbars();

    }, 2500);

});

$(function (){
    // PRODUCT SWIPER
    if($("#visual_scroller .swiper-slide").length) {
        var productThumbs = new Swiper('#visual_scroller', {
            slidesPerView: 4,
            spaceBetween: 17,
            direction: 'vertical',
            loop: false,
            navigation: {
                nextEl: '.thumbNext',
                prevEl: '.thumbPrev',
            },
        });
    } else {
        $("#visual_scroller_wrapper").addClass('no_swiper');
    }

    if($("#jqzoom .swiper-slide").length > 1) {
        //animation to avoid slide's width transition on pageload
        $('#jqzoom').animate({
            opacity: 1,
        },10);
        var main_productVisu = new Swiper('#jqzoom', {
            loadOnTransitionStart: true,
            thumbs: {
                swiper: productThumbs
            },
            pagination: {
                el: '.swiper-pagination',
                type: "bullets",
                clickable: true
            },
            slidesPerView: 1,
            loop: false,
        });
    }
    if ($("body").hasClass("product_page")) {
        var swiper_FP_main_push_height = $("#jqzoom .swiper-wrapper.productBigPictures .img_large").height();
        var swiper_FP_main_push_width = $("#jqzoom").width();

        $("#visual_scroller").height(swiper_FP_main_push_height);
        $(window).on('resize', function () {
            setTimeout(function(){
                var swiper_FP_main_push_height = $("#jqzoom .swiper-wrapper.productBigPictures .img_large").height();
                var swiper_FP_main_push_width = $("#jqzoom").width();

                $("#visual_scroller").height(swiper_FP_main_push_height);
            }, 250)
        });
    }
});