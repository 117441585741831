/*class GSetting {
    constructor () {
        this.IsOperatedByGlobalE = true;
    }
}
class GLocalize {
    constructor () {
        this.UserCountry = 'IE';
    }
}

class GInstance {
    constructor () {
        this.UserLocalizeSettings = new GSetting();
        this.LocalizeConfigurations = new GLocalize();
    }
}

class Gconf {
    constructor () {
        this.Instance = new GInstance();
    }
}

class GEM_Services {
    static ConfigurationsService = new Gconf();
}*/

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css('display');

    if (!loader.parent().hasClass('loading')) {

        loader.parent().addClass("loading");

        switch (action) {

            case 0:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuthTop, 1, path_relative_root);
                break;

            case 1:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwdTop, 1, path_relative_root);
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass('loading');
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(ajax_checkAllForm, 1000, tabAuth, 1, path_relative_root);
                break;

            case 5:
                window.setTimeout(ajax_checkAllForm, 1000, tabLostPwd, 1, path_relative_root);
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (checkAllForm(tabErrorGrattez, 0, path_relative_root, id_form)) {

                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader()
{
    if (loader) {
        loader.css('display', 'none');
        loader.removeAttr("style").parent().removeClass("loading");
    }
    if (elmt)   { elmt.css('display', prev_display); }
}

// Checks any form
function ajax_checkAllForm(tabError, blockMenuTopConnexionInfobulle, path_relative_root) {

    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {

        if (tabError[0][1] && tabError[0][1] == 'clientMailAuthTop') {

            $.ajax({
                url : path_relative_root + 'ajax_login_allowed.php',
                type : 'get',
                data : {
                    mail : document.getElementById(tabError[0][1]).value,
                    mdp : document.getElementById(tabError[1][1]).value,
                    is_top : true
                },
                success : function (res) {

                    var url, index_deb, index_fin, provenance;

                    if (res[0] !== '0') {

                        url = window.location.pathname;
                        index_deb = url.lastIndexOf('/');
                        index_fin = url.lastIndexOf('.');
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === 'connexion') {

                            window.location.replace(path_relative_root + create_link('client_index'));
                        } else if (provenance === 'identification') {

                            window.location.replace(path_relative_root + 'livraison-kiala.php');
                        } else {

                            window.location.reload();
                        }
                    } else {

                        document.getElementById('AuthTopErr').style.display = 'block';

                        $('.loader').css('display', 'none');
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLostTop') {

            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url : path_relative_root + 'ajax_email_exist.php',
                type : 'get',
                data : { mail : mail },
                success : function (res) {

                    if (res == 1) {

                        $.ajax({
                            url : path_relative_root + 'ajax_send_new_password_link.php',
                            type : 'get',
                            data : { mail : mail },
                            succes : function () {

                                openMultiShad('popup_confirm_reinitialisation_mdp');
                                document.getElementById('lostpwdTopErr').style.display = 'none';
                                document.getElementById('lostpwdTopSuccesTitle').style.visibility = 'hidden';
                                hideLoader();
                            }
                        });
                    } else {
                        document.getElementById('lostpwdTopErr').style.display = 'block';
                        hideLoader();
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'clientMailAuth') {

            $.ajax({
                url : path_relative_root +'ajax_login_allowed.php',
                type : 'get',
                data : {
                    mail : document.getElementById(tabError[0][1]).value,
                    mdp : document.getElementById(tabError[1][1]).value,
                    is_top : false
                },
                success : function (res) {

                    hideLoader();

                    if (res == 1) {

                        $('#auth').submit();

                        return true;
                    } else {

                        document.getElementById('AuthErrErr').style.display = 'block';

                        return false;
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLost') {

            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url : path_relative_root + 'ajax_email_exist.php',
                type : 'get',
                data : { mail : mail },
                success : function (res) {

                    if (res == 1) {

                        $.ajax({
                            url : path_relative_root+ 'ajax_send_new_password_link.php',
                            type : 'get',
                            data : { mail : mail },
                            success : function (res) {

                                openMultiShad('popup_confirm_reinitialisation_mdp');
                                document.getElementById('lostpwdErr').style.display = 'none';
                                document.getElementById('lostpwdSuccesTitle').style.visibility = 'hidden';
                                hideLoader();

                                return false;
                            }
                        });
                    } else {

                        document.getElementById('lostpwdErr').style.display = 'block';
                        hideLoader();

                        return false;
                    }
                }
            });
        }
    } else {

        $('.loader').css('display', 'none');
    }
}

// Ensures this.value is non-empty
// Binds to HTMLInputElement
// Returns true if value is non-empy, false otherwise
function nonEmpty() {

    return !!this.value.trim();
}

// Adds "display" rule to bond element's style with a value of "block"
function show() {

    this.style.display = "block";
}

// Adds "display" rule to bond element's style with a value of "none"
function hide() {

    this.style.display = "none";
}

// Adds leading zeroes to a given value
function zeroPad(nbr, base) {

    var zpad;

    base = (parseInt(base) > 1) ? base : 10;
    zpad = (nbr < base) ? '0' : '';

    return zpad + nbr.toString(base).toUpperCase();
}

// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : create_link('order_basket'),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'less') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "remove_from_cart");
                }

                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'plus') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "add_to_cart");
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;
            }
        });
    }
}

/**
 * Loads product data and updates the page content accordingly.
 *
 * @param {string} new_id
 * @param {string} type_filter - The type of filter.
 * @param {string} page - The type of page (optional).
 * @param {number} itm_rr_id - The ID of the product's grouping (optional).
 */
function loadProduct(new_id, type, page, itm_rr_id) {

    if (itm_rr_id === undefined) {
        itm_rr_id = '';
    }

    var form = this;

    // Loading
    var btnLoading = $('.btnAddBasketWrapper ', $(this));

    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var sizeCharactId = $('input[name="itm_size_charact"]:checked').val();
    var act = this.getValue('act');
    var qte_itm = 1;
    var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

    if (this.elements.namedItem('qteProd')) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }
    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var lookbook = $('#lookbook').val() == 1;
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : is_wishlist || lookbook ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        isCategSuper : isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    if (typeof GEM_Services != "undefined") {
        data.warehouse_only = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
    }

    var switch_product_assoc = false;

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;
                if (data.type_page == 'product') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var newItmIds = datas.html_main.match(/itm-([0-9-]*)/);
                    var end_itm_id = '';
                    if (newItmIds.length == 2) {
                        end_itm_id = newItmIds[1].replace('-','_');
                    }

                    var assoc_products = $('.wrap_gondole', $html_main);
                    if (data.is_achat_express_v2 || (data.is_achat_express && !lookbook)) {
                        // Photo and information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        // We are on a product's page
                        if ($(".product_page").length > 0) {
                            switch_product_assoc = true;
                            $('#itm-' + itm_id).replaceWith($html_main);
                        } else if ($('#itm-' + itm_id) != undefined && $('#itm-' + itm_id).length == 1) {
                            $('#itm-' + itm_id).replaceWith($html_main);
                        } else {
                            $('#prod_info' + itm_id).replaceWith($html_main);
                        }

                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                        $("#couleurProd_" + end_itm_id).val(data.couleurProd);
                    } else {
                        var $html_middle = $(stripCombo(datas.html_middle));
                        var color = $("#ligne_couleur").html();
                        var taille = $("#ligne_pointure").html();

                        var main_wrapper = $('.product_main_wrapper', $html_main);
                        var productVisu = $('#productVisu .productVisu', $html_main);
                        var product_info = $('.product_info', $html_main);
                        var product_selects = $('#product_selects', $html_main);

                        var feats_wrapper = $('.product_feats_wrapper', $html_main);

                        var contents = $('<div />').html($html_main);

                        if ($(".choices_list.opened").length) {
                            exitProductFieldset();
                        }

                        if (assoc_products.length == 2) {
                            var ranges  = $('.wrap_gondole .ranges', $html_main).html();
                            var associations  = $('.wrap_gondole .associations', $html_main).html();

                            $('.wrap_gondole .associations').html(associations);
                            $('.wrap_gondole .ranges').html(ranges);
                        } else {
                            $('.wrap_gondole').html(assoc_products);
                        }

                        // Photo and information
                        if (lookbook) {
                            $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        } else {
                            $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        }

                        if (productVisu.length > 0) {
                            $('#productVisu').html('');
                            productVisu.each(function(){
                                $('#productVisu').append($(this));
                            })
                        } else {
                            $('#productVisu').html(productVisu.html());
                        }

                        $('.product_info').html(product_info.html());

                        // Composition description, delivery, size guide
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);
                        $('input#size_charact_'+sizeCharactId).prop('checked', true);

                        $('#product_selects').html(product_selects.html());

                        if (typeof res.datas.sizes !== "undefined") {
                            var size_length = res.datas.sizes.length;
                            var k = 0;
                            for (k = 0; k < size_length; k++) {
                                var size = res.datas.sizes[k];
                                var img = $("#size_" + size.id).parent().find('img');
                                if (typeof img[0] !== "undefined") {
                                    var path = size.url_img;
                                    var path_img = path.replace(new RegExp('large\/' + size.produit_ref + 'A', 'i'), 'mini/' + size.produit_ref + 'Z');
                                    img[0].setAttribute('src', path_img);
                                }
                            }
                        }
                        // Update size for cabas
                        if (typeof res.datas.product.size !== "undefined") {
                            var newSize = res.datas.product.size;
                            $("#js-sizeName").html(newSize.nom);
                            $(".size_choice").html(newSize.nom);
                            $("#tailleProd").val(newSize.id);

                            if ($('#size_' + newSize.id).length) {
                                $('#size_' + newSize.id).prop('checked', true);
                            }
                        }
                    }

                    if ($("#buyNowSwiper").length > 0) {
                        var buyNowSwiper = document.getElementById("buyNowSwiper");
                        if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {
                            buyNowSwiper = new Swiper("#buyNowSwiper", {
                                sliderPerView: 1,
                                spaceBetween: 50,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    }

                    if ($('.product_page').length && $('.cta_availability_wrapper').length) {
                        if (typeof GEM_Services != "undefined") {
                            var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                            if (!isOperatedByGlobalE) {
                                showCtaAvailableFaq();
                            }
                        } else {
                            showCtaAvailableFaq();
                        }
                    }
                } else if (type_page == 'look') {
                    var $html_main = $(stripCombo(datas.html_main));

                    var $html_middle = $(stripCombo(datas.html_middle));

                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    var contents = $('<div />').html($html_main);

                    // Photo et information
                    $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                    // Description compo, livraison, guide tailles
                    $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());


                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                }

                json_colisages = res.stock;
                // We define the sizes that still have stock
                setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));

                // Add the swipers
                $('.productVisualMulti').on('mouseenter', function() {
                    if ($(this).find('.swiper-slide').length > 1 && !$(this).hasClass('swiper-container-initialized')) {
                        new Swiper($(this), {
                            slidesPerView: 'auto',
                            navigation: {
                                nextEl: '.rollover_rayonsNext',
                                prevEl: '.rollover_rayonsPrev',
                            },
                            preloadImages: false,
                            lazy: isLazyLoad,
                            loadPrevNext: true,
                            loadOnTransitionStart: true,
                            watchSlidesVisibility: true
                        });
                    }
                });

                if (wShop.$refs.wAlertestockForm != undefined) { // Utiliser sur la fiche produit car on utilise le composant wshop
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                // Update size for cabas
                var newProductId = data.produit_id_new;
                if (typeof res.datas.product.size !== "undefined") {
                    newProductId = res.datas.product.id;
                }

                getAvailableQuantity.call(form, newProductId, data.qteProd, 'product', itm_rr_id, is_wishlist, newProductId);

                $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

                if (!data.is_achat_express_v2) {
                    checkProductInWishlist(data.produit_id_new);
                }

                if (is_wishlist) {
                    // event sur le bouton d'ajout à la wishlist
                    $("#addToWishlistButton").on("click", addToWishlist);
                }

            } else {
                $( '#btn_error' ).fadeIn();
            }

            btnLoading.removeClass('loading');
        },
        complete : function () {
            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();
                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function() {
                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();
            }, 250);

            // PRODUCT SWIPER
            if($("#visual_scroller .swiper-slide").length) {
                var productThumbs = new Swiper('#visual_scroller', {
                    slidesPerView: 4,
                    spaceBetween: 17,
                    direction: 'vertical',
                    loop: false,
                    navigation: {
                        nextEl: '.thumbNext',
                        prevEl: '.thumbPrev',
                    },
                });
            } else {
                $("#visual_scroller_wrapper").addClass('no_swiper');
            }

            if($("#jqzoom .swiper-slide").length > 1) {
                //animation to avoid slide's width transition on pageload
                $('#jqzoom').animate({
                    opacity: 1,
                },10);
                var main_productVisu = new Swiper('#jqzoom', {
                    loadOnTransitionStart: true,
                    thumbs: {
                        swiper: productThumbs
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: "bullets",
                        clickable: true
                    },
                    slidesPerView: 1,
                    loop: false,
                });
            }

            if ($(".cabas .choices_list .wrapper_select").length) {
                $(".cabas .choices_list .holder_scrollable").overlayScrollbars({});
            }

            heads.each(function() {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            initFPElements();

            // Initialisation du slider si présent (tete de gondole)
            var swiperContainer = document.getElementById('homeSwiper');
            if (swiperContainer) {
                new Swiper('#homeSwiper', {
                    slidesPerView: 2,
                    spaceBetween: 66,
                    loop: false,
                    navigation: {
                        nextEl: '.slider-module .swiper-button-next',
                        prevEl: '.slider-module .swiper-button-prev'
                    }
                });
            };
            setTimeout(function(){
                if ($("body").hasClass("product_page")) {
                    var swiper_FP_main_push_height = $("#jqzoom .swiper-wrapper.productBigPictures .img_large").height();
                    var swiper_FP_main_push_width = $("#jqzoom").width();
                    $("#visual_scroller").height(swiper_FP_main_push_height);
                }},
                250
            )
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    if (itm_rr_id === undefined) {
        itm_rr_id = '';
    }
    var control, prod_id, color_name;

    if (this.checked) {

        // Loading
        $(form).children('.form_submit').addClass('loading');

        control = form.elements.namedItem(form.getValue('idCouleur'));

        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur' , '', itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            var in_wishlist = checkProductInWishlist(prod_id, itm_rr_id);
            var img = $(this).data('pic');

            if (is_achat_express) {
                var elt = $(this).closest('.imgWrapper').children('.imgProd')[0];
            } else {
                var elt = document.getElementById('img_large');
            }
            // Changement de l'image
            changeVisu(elt, img);
            changeBtnAddWishlist(in_wishlist, prod_id, itm_rr_id);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {

    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = $('#produit_principal' + elem).val();
    var productId = false;
    if (this.getAttribute('data-produitid')) {
        productId = this.getAttribute('data-produitid');
    }

    if (this.checked) {

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';
        var prod_id = false;

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        var id_selected = this.value;
        $("#ligne_pointure_error"+elem).remove();

        // On regarde si le produit est dejà dans la wishlist
        checkProductInWishlist(id, itm_rr_id);
        if (document.getElementById('size_' + id_selected)) {
            prod_id = typeof document.getElementById('size_' + id_selected).getAttribute('data-product-id') !== "undefined" ? document.getElementById('size_' + id_selected).getAttribute('data-product-id') : false;

            if (prod_id) {
                loadProduct.call(form, prod_id, 'couleur' , '', itm_rr_id);

                if (document.getElementById('size_' + id_selected)) {
                    var name_size = document.getElementById('size_' + id_selected).getAttribute('data-nom').trim();
                    if ($(".size_choice") && name_size) {
                        $(".size_choice").html(name_size);
                    }

                    if ($("#tailleProd") && id_selected) {
                        $("#tailleProd").val(id_selected);
                    }
                }
            }
        }
        getAvailableQuantity(id, $('#qteProd' + elem).val(), 'product', itm_rr_id, is_wishlist, productId);
    }
}

// Called upon finish change in product page (only for regroup)
function onItemSizeCharactChange(form) {

    var prod_id;

    if (this.checked) {
        prod_id = this.value;
        loadProduct.call(form, prod_id, 'sizeCharact', '');
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, itm_rr_id, is_wishlist, prod_id = false) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var elemId = is_achat_express ? '_' + id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();

    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }
    let produitId = 0;
    if (prod_id) {
        produitId = prod_id;
    } else {
        produitId = form.getValue('produit_id');
    }
    var data = {
        idprod: produitId,
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from === 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            try  {
                if (typeof GEM_Services != "undefined") {
                    var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                }
            } catch (e) {
                console.log(e);
            }

            // Check if globale delivery country
            // OR is express checkout AND have no warehouse stock AND product not SFS
            if (res.disable_addtocart == true && ((typeof isOperatedByGlobalE !== 'undefined' && isOperatedByGlobalE !== false)
                || (is_achat_express && (typeof isOperatedByGlobalE == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)))) {
                res.stock_tampon = 0;
            }

            var blockAlert = $("#bloc_add_alert" + elem,$(form));
            if (res.stock_tampon == 0) {

                $(".eclat_product_nouveaute_fiche_produit").show();

                if (!is_achat_express) {
                    $("#label_" + size_id).addClass("disabled");
                    $("#size_" + size_id).addClass("disabled");
                    $("#ligne_couleur").addClass("epuise");
                    $(".productDescrWrapper .date_livraison").addClass("epuise");
                    $("#bloc_add_basket").addClass("disabled");

                    $('#ligne_qte').append($('.restock_date'));
                    $('.taunt_text').css('display', 'none');
                } else {
                    var new_id_produit = id + itm_rr_id;
                    if ($('#tailleProd_' + new_id_produit, $(form)) != undefined && $('#tailleProd_' + new_id_produit, $(form)).length > 0) {
                        var input_size = $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form));

                        if (input_size != undefined && input_size.length > 0) {
                            $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form)).addClass('disabled');

                            // Refreshing inventory in memory
                            if (array_stock_product != undefined && array_stock_product[produitId] != undefined && array_stock_product[produitId][color_id] != undefined) {
                                array_stock_product[produitId][color_id][size_id] = 0;
                            }
                        }
                    }
                }

                blockAlert.show();
                blockAlert.addClass("actif");

                if ($('#bloc_add_basket',$(form)) != undefined) {
                    $('#bloc_add_basket',$(form)).hide();
                }
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                $(".eclat_product_nouveaute_fiche_produit").hide();
                if (!is_achat_express) {
                    $("#label_" + size_id).removeClass("disabled");
                    $("#size_" + size_id).removeClass("disabled");
                    $("#bloc_add_basket").removeClass("disabled");
                    $("#ligne_couleur").removeClass("epuise");
                    $(".productDescrWrapper .date_livraison").removeClass("epuise");

                    $('#bloc_add_basket').removeClass('inactif');
                    $('#btn_add_cart').removeAttr('disabled');
                } else {
                    var new_id_produit = id + itm_rr_id;
                    if ($('#tailleProd_' + new_id_produit, $(form)) != undefined && $('#tailleProd_' + new_id_produit, $(form)).length > 0) {
                        var input_size = $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form));

                        if (input_size != undefined && input_size.length > 0) {
                            $('#size_' + id + "_"+ $('#tailleProd_' + new_id_produit, $(form)).val() + itm_rr_id, $(form)).removeClass('disabled');

                            // Refreshing inventory in memory
                            if (array_stock_product != undefined && array_stock_product[produitId] != undefined && array_stock_product[produitId][color_id] != undefined) {
                                array_stock_product[produitId][color_id][size_id] = res.stock_tampon;
                            }
                        }
                    }
                    if ($('#product_detail' + elemId).length) {
                        $('#product_detail' + elemId).hide();
                    }
                    $('#bloc_add_color' + elem).css('display', 'block');
                }
                blockAlert.hide();
                blockAlert.removeClass("actif");

                if ($('#bloc_add_basket').length == 1) {
                    $('#bloc_add_basket').show();
                }

                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                    //DataLayerTrigger.viewItem(res.dataLayer_object);
                } else {
                    pushIntoDatalayer(res.dataLayer_object);
                }
            }

            // Check if globale delivery country
            if (res.disable_addtocart == true) {

                //globa_e global param
                if (typeof GEM_Services != "undefined") {
                    var userCountry = GEM_Services.ConfigurationsService.Instance.LocalizeConfigurations.UserCountry;
                }

                if ((typeof userCountry !== "undefined" && userCountry.IsOperatedByGlobalE == true) || ((typeof userCountry === "undefined" || typeof userCountry !== "undefined" && userCountry.IsOperatedByGlobalE == false) && typeof res.is_sfs !== "undefined" && res.is_sfs == false))  {

                    // Achat express v2
                    if (is_achat_express) {
                        if ($('#bloc_add_color' + elem).length && $('#bloc_add_alert' + elem).length) {
                            $('#bloc_add_color' + elem).hide();
                            $('#bloc_add_alert' + elem).show();

                        }
                    } else {
                        $("#bloc_add_basket").hide();
                        $("#bloc_add_alert").show();
                    }
                }

                // Bouton personnalize
                if (typeof res.is_personalize !== "undefined" && res.is_personalize == true) {
                    $(".btn_personalize").hide();
                }
            } else {
                // Bouton personnalize
                if (typeof res.is_personalize !== "undefined" && res.is_personalize == true && res.stock_tampon > 0) {
                    $(".btn_personalize").show();
                } else {
                    $(".btn_personalize").hide();
                }
            }
        }
    });
}

function waitingAddToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {

    var id = '';
    if( is_not_produit == true )
        id += '_' + produit_id;

    if ( is_lightbox_specifique )
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout('addToBasket_legacy("'+produit_id+'", "'+suff+'", "'+panier_id+'", "'+titreObjet+'", "'+idTaille+'", "'+lastTaille+'", "'+idCouleur+'", "'+lastCouleur+'","' + toEval + '", "'+page_name+'", "'+path_web+'", '+enable_omniture+', '+is_not_produit+');',1000);

    $("#bloc_btn_active"+id).attr('class','cache');
    $("#bloc_btn_loader"+id).attr('class','f_right');

}

function addToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {

    var id = '';

    if( is_not_produit == true )
        id += '_' + produit_id;

    prix_id = 'prixU';
    if( is_not_produit == true ) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_'+produit_id;
    }

    var array_panier = newUpdatePanier_legacy(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false)
    {
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0)
        {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file( path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response);

        if (array_panier[1] === 0)
        {
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        }
        else if (array_panier[1] == 1)
        {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        }
        else
        {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active"+id).attr('class','');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true)
        {
            void(s.t());
            s.pageName  = "'" + page_name + "'";
            s.products  = ";" + produit_id;
            s.events    = omnitureEvent;
            void(s.t());
        }
    }
    else {

        $("#bloc_btn_active"+id).attr('class','');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');

    }

    eval(toEval);

    if (array_panier != false)
    {
        if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 )
        {
            var svn=document.getElementsByTagName("SELECT");
            for (a=0;a<svn.length;a++)
            {
                svn[a].style.visibility="hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur){
    if (i != '')
    {
        i = '_'+i;

        prixU = $('#prixU'+i).val();
    }

    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);
    var $alertbox = $( '#alertbox1' );
    var res = '';
    var html_err = '';
    var flagError = false;
    if($('#lightbox_achat_express').length>0){
        from_range=true;
    }else{
        from_range=false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
        $('body').append($alertbox.hide());
    }

    if(tailles && tailles.value == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_size');
        flagError = true;

    } else if(couleurs && couleurs.value == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;

    } else if (document.getElementById('qteProd'+i) && document.getElementById('qteProd'+i).value == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError)
    {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1');
        return false;
    }
    else {

        var qte = 0;

        if ($('#qteProd'+i))
        {
            qte = $('#qteProd'+i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
        if (tailles && tailles.value != 'none') idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(path_web+'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if ( titreObjet.match('^[0-9]') ) {

                if ( designation == 1 ) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if ( document.getElementById(idElTaille) ) {

                if ( document.getElementById(idElTaille).selectedIndex ) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;


                } else if ( lastTaille != '' ) {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ( document.getElementById(idElCouleur) ) {

                if ( document.getElementById(idElCouleur).selectedIndex ) {
                    designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;

                } else if ( lastCouleur != '' ) {

                    designation += ' - ' + lastCouleur;

                }

            }

            array_response[0] = designation;

        }

        return array_response;
    }
}

// Pretty straightforward: this formats a price
var formatPrice = (function () {

    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {

        var f_chunk = '', chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {

            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return f_chunk + nmbr.replace(THREEDIGITS, function (match) {

            return ' ' + match;
        });
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;

        var output;

        if (price.constructor !== String) {

            price = price.toString(10);
        }

        price = price.replace('.', ',');
        price = price.split(',');

        if (price[1] === undefined) {
            price[1] = '00';
        }

        if (typeof window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'undefined' && window.PRICE_FORMAT_PLAIN_JS_NO_CENTS !== 'PRICE_FORMAT_PLAIN_JS_NO_CENTS') {
            if (price[1] === '00' || price[1] === '') {
                FORMAT = window.PRICE_FORMAT_PLAIN_JS_NO_CENTS;
            }
        }

        output = FORMAT.replace('[<unit>]', splitThousands(price[0]));
        output = output.replace('[<symbol_right>]', CURRENCY.symbol_right);
        output = output.replace('[<symbol_left>]', CURRENCY.symbol_left);
        output = output.replace('[<decimal>]', price[1]);
        output = output.replace('[<code>]', CURRENCY.code);

        return output;
    };
}());

// Limits the length of an input
function limitInputLength(max) {

    this.value = this.value.slice(0, max);

    return this;
}

var provenance = '',
    sous_provenance = '';

if (typeof $('#newsletter_email').attr('data-provenance') != 'undefined')
    provenance = $('#newsletter_email').attr('data-provenance');

if (typeof $('#newsletter_email').attr('data-sousprovenance') != 'undefined')
    sous_provenance = $('#newsletter_email').attr('data-sousprovenance');

// INSCRIPTION NEWSLETTER
// La gestion de l'inscription à la newsletter
$('input#newsletter_home').click(function(){

    $.ajax({
        url : path_relative_root + 'ajax_newsletter_email.php',
        type : 'post',
        data : {
            email : $('#newsletter_email').val(),
            provenance : provenance,
            sous_provenance : sous_provenance
        },
        cache : false,
        success : function (res) {
            var resultat = JSON.parse(res);
            if(resultat.valid == false){
                $('#newsletter_email').addClass('inputErr');
                $('#error_newsletter').removeClass('valid');
            } else{
                $('#error_newsletter').removeClass('error');
                $('#error_newsletter').addClass('valid');
                $('#newsletter_email').removeClass('inputErr');
            }
            $('#error_newsletter').html(Translator.translate(resultat.message));
        }
    });
});

/**
 *
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;
    var form = document.getElementById('prod_info' + elem);

    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    var tailles = $("#tailleProd" + elem);

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        $('#bloc_add_alert'+elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            if (tailles.length && tailles.val() == "") {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size')+'</div>';
                btn.parent('.form_submit').removeClass('loading');
                $(txt_error).insertBefore($("#size_list"+elem));
            }
            waitingAddToBasket(event, form, '', regroup_ref_id);
            setTimeout(function() {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else {
            if (color_id === '' && size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size_and_a_color')+'</div>';
            } else if (color_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_color')+'</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size')+'</div>';
            }
            // Loader
            btn.parent('.form_submit').removeClass('loading');
            $(txt_error).insertBefore($("#ligne_pointure"+elem));
        }
    }
}

function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("notLogged")) {
        location.href=path_relative_root+create_link('order_identification')+'?from=giftcard';
    } else {
        $(".pageGiftCard__header .headerTitles").toggleClass('is-hidden', '');
        $(".pageGiftCard__main").toggleClass('is-hidden', '');
        $(".showAmountBtn").toggleClass('is-hidden', '');
    }
};

$(function() {
    if (window.location.href.slice(window.location.href.indexOf('?')) === '?from=giftcard') {
        $('#subtitleLogin').css('display', 'none');
        var giftCardConnect = "<p class=\"subtitle\">" + Translator.translate('gift_card_connect') + "</p> ";
        $('.login_form_wrapper .title h3').after(giftCardConnect);
    }

    $(".dropdown_cms").click(function () {
        $(this).next('ul.menu_cms').slideToggle();
    });
});

function showCtaAvailableFaq() {
    if ($('.product_page').length && $('.cta_availability_wrapper').length) {
        $('.cta_availability_wrapper').show();
    }

    // Hide FAQ theme_reponse when global E is inactive
    if ($('.faq .theme_reponse').length) {
        $('.theme_reponse').show();
    }
}

var isOperatedByGlobalE = false;

$(document).ready(function() {
    setTimeout(function() {
        // Global E -> disablings when active
        if (typeof GEM_Services != "undefined") {
            try {
                isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
            }
            catch (e) {
                isOperatedByGlobalE = false;
            }
            if (!isOperatedByGlobalE) {
                showCtaAvailableFaq();
            }
        } else {
            showCtaAvailableFaq();
        }

        if (isOperatedByGlobalE) {
            $('body').addClass('isOperatedByGlobalE');
            $('.disable_globale').each(function() {
                if (!$(this).hasClass('disabled')) {
                    $(this).addClass('disabled');
                }
            });
        } else {
            $('body').removeClass('isOperatedByGlobalE');
        }
    }, 300);
});

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

/* PROD ASSOCS DATA LAYERS */
function dataLayer_associated_product () {

    if ($(this).parents("#associations_slider").length) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            if ( data_layer_products_look.length > 0 ) {
                data_layer_products_look.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }

            var prod = productObj;
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': prod['category']},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });

            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null ) {
                DataLayerTrigger.selectItem(dl_v3_impressions_look, dlv3_action_fields_look, product_id );
            }
        }
    }

    if ($(this).parents("#ranges_slider").length) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }

            var prod = productObj;
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': prod['category']},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });

            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null ) {
                DataLayerTrigger.selectItem(dl_v3_impressions_like, dlv3_action_fields_like, product_id );
            }
        }
    }
}

function deleteItemFromCart (id, qty) {
    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && id !== null && id !== undefined) {
        DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, id, "remove_from_cart", qty);
    }
}